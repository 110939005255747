<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>品牌出海行业分类</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in hangyeList" :key="index" @click="goTab(item.id)">
                        <div :class="{active:item.id==id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path:'/cyppch'}">产业品牌出海</el-breadcrumb-item>
                        <!-- <el-breadcrumb-item>品牌出海行业分类</el-breadcrumb-item> -->
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="liebiao_wrap">
                        <div class="liebiao" :class="{active:plate_id==item.id}" v-for="(item,index) in hangyeList1" :key="index" @click="goTab1(item.id)">{{item.name}}</div>
                    </div>
                    <div class="details-text">
                        企业列表
                        <div class="button" @click="goTo('pinpai_join',{id})">
                            <img src="../../assets/images/button.png" alt="">
                            <p>会员在线提交表单</p>
                        </div>
                    </div>
                    <div class="details-list">
                        <div class="details-p">点击公司名称，进入对应公司详细介绍：</div>
                        <div class="details-item-wrap">
                            <div class="details-item" v-for="(item,index) in gongsiList" :key="index" @click="goTo('pinpai_details',{id:item.id,plate_id:id,key:1})">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getplates,getplatexts} from '../../api/index'
    import {mapState, mapMutations, mapActions} from 'vuex'  
    export default {
        data() {
            return {
                id:'',
                plate_id:'',
                hangyeList:[], 
                hangyeList1:[], 
                gongsiList:[]            
            }
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            if(this.$route.query.id){
                this.id=this.$route.query.id
            }            
            this.getplates() 
              
             
        },
        methods: {    
            // 跳转页面
            goTo(url,query) {
                if (url=='pinpai_join') {
                    if ( !this.isLogin) {
                        this.$router.push({
                            path:'/login'
                        })
                        return
                    }                    
                }
                
                this.$router.push({ 
                    path: url ,
                    query
                });
            },     
            goTab(id){
                this.id=id;
                this.getplates1()
            },  
            goTab1(id){
                this.plate_id=id;
                this.getplatexts()
            },  
            getplates(){
                getplates({
                    p_id:20
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.hangyeList=res.data
                        if(this.$route.query.id){
                            this.id=this.$route.query.id
                            this.getplates1()    
                        }else{
                            this.id=res.data[0].id
                            this.getplates1()    
                        }
                        
                    }
                })
            },
            getplates1(){
                getplates({
                    p_id:this.id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.hangyeList1=res.data
                        if (res.data.length>0) {
                            this.plate_id=res.data[0].id
                            this.getplatexts()  
                        }else{
                            this.plate_id=this.id
                            this.getplatexts()
                        }
                    }
                })
            },
            
            getplatexts(){
                getplatexts({
                    plate_id:this.plate_id
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.gongsiList=res.data.data
                    }
                })
            }
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;  
            max-height: 70vh;
                overflow: auto;
                &::-webkit-scrollbar {
                width:5px;
                }
                /* 滚动槽 */
                &::-webkit-scrollbar-track {
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
                border-radius:10px;
                }
                /* 滚动条滑块 */
                &::-webkit-scrollbar-thumb {
                border-radius:10px;
                background:rgba(0,0,0,0.1);
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
                }
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 0;padding-top:0;
                .liebiao_wrap{
                    display: flex;
                    flex-wrap: wrap;
                    padding: 30px;
                    .liebiao{
                        width: 16.666666%;
                        text-align: center;
                        font-size: 16px;
                        padding: 10px 0;
                        cursor: pointer;
                    }
                    .active{color: #0071DB ;text-decoration: underline;}
                }
                .details-text{
                    color: #555555;font-size: 28px;margin-bottom: 40px;text-align: center;
                    position: relative;
                    .button{
                        width: 151px;height: 43px;position: absolute;cursor: pointer;
                        right: 35px;top:50%;transform: translateY(-50%);
                        img{width: 100%;height: 100%;}
                        p{width: 151px;height: 43px;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 16px;line-height: 43px;text-align: center;}
                    } 
                }
                
                .details-list{
                    margin: 50px;
                    .details-p{
                        color: #555555;font-size: 18px;
                        span{font-size: 16px;}
                    }
                    .details-item-wrap{
                        .details-item{
                           margin:40px 0;cursor: pointer;font-size:18px;color:#555555;
                            &:hover{
                                color: #FE6D00;text-decoration:underline;
                            }
                        }
                    }

                }
            }
        }
        
    }

</style>